import axios from '@axios'

const PREFIX = 'cnd-portal-documentos'

export default {

  getPaginado(page, pageSize, filtros, filtroString) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}&multiFiltro=${filtroString}`, filtros)
  },

  getURL(id, coluna) {
    return axios.get(`${PREFIX}/url?empresaId=${id}&coluna=${coluna}`)
  },

  baixaEmLote(filtroDocs, nome, baixarLinha = false) {
    return axios.post(`${PREFIX}/baixa-lote?nomeArquivo=${nome}&baixarLinha=${baixarLinha}`, filtroDocs)
  },

  enviarEmLote(nome, filtroDocs) {
    return axios.post(`${PREFIX}/enviar-email?nomeArquivo=${nome}`, filtroDocs)
  },

  getObservacoes(id) {
    return axios.get(`${PREFIX}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },

  baixarEmExcel(tipoCnd, filtroDoc) {
    return axios.post(`${PREFIX}/${tipoCnd}/baixa-excel`, filtroDoc)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },
}
