<template>
  <b-card>
    <cnd-portal-documentos-list
      ref="cndPortalDocumentosList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      :tem-filtro-extra="true"
      :show-botao-custom="true"
    >
      <template slot="show-botao-custom">
        <b-row>

          <b-col cols="auto">
            <download-em-excel
              :filtro="filtroAtual"
              tipo="CNDs Portal"
              alterar-style="padding: 0.8rem;"
              :api="apiPortal"
              :get-info-extra="montarDoc"
            />
          </b-col>
          <b-col cols="auto">
            <b-btn
              variant="none"
              class="btn btn-outline-primary"
              size="sm"
              style="white-space: nowrap; padding: 0.75rem 1rem;"
              @click="enviarEmailLoteGeral()"
            >
              <feather-icon
                icon="SendIcon"
                class="text-primary"
                size="15"
              />
              Enviar por e-mail
            </b-btn>
          </b-col>

          <b-col cols="auto">
            <v-select
              id="baixaPortalSelect"
              :ref="`SelectFiltroDocs`"
              v-model="tipoDownload"
              v-b-tooltip.hover
              :options="opcoesBaixaEmLote"
              :clearable="true"
              label="nome"
              placeholder="Baixar documentos"
              style="font-size: 14.1px; width: 230px;"
              @input="baixaEmLoteGeral()"
            />
          </b-col>

        </b-row>
      </template>
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'rfbPgfn' ||
            props.column.field === 'fgts' ||
            props.column.field === 'trabalhista' ||
            props.column.field === 'mte' ||
            props.column.field === 'estadual' ||
            props.column.field === 'municipal'"
          :variant="getColorTag(props.row[props.column.field])"
          @click="openModalVisualizacaoArquivo(props.row, props.column.field)"
        >
          <span v-html="getDescricao(props.row[props.column.field])"/>
        </b-badge>
      </template>
      <template slot="filtro-extra">
        <b-row>
          <b-col cols="12">
            <v-select
              :ref="`SelectFiltroDocs`"
              v-model="filtroString"
              :options="filtrosRapidosDocs"
              :clearable="true"
              multiple
              deselect-from-dropdown
              placeholder="Tipos de documentos"
              @input="mudarDocSelecionado($event)"
            />
          </b-col>

        </b-row>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiPortal"
          :filtros-rapidos="filtrosRapidos"
          :filtros-tipo-cnd="filtroString"
          :filtro-atual="filtroAtual"
          @enviar-email="enviarEmailLinha($event.linha, $event.email)"
        />
      </template>
    </cnd-portal-documentos-list>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Enviando'"
    />
    <visualizar-arquivo
      v-if="mostarArquivo && arquivo"
      :url="arquivo"
      @hidden="fecharArquivo()"
    >
      <template v-slot:cabecalho-html>
        <slot
          name="cabecalho"
          :empresa-nome="empresaNome"
        />
      </template>

      <template v-slot:rodape-html>
        <slot
          name="rodape"
          :abrir-arquivo="openVisualizacaoArquivo"
          :fechar="fecharArquivo"
        />
      </template>
    </visualizar-arquivo>
  </b-card>
</template>

<style lang="scss">
.col-valores{
  white-space: nowrap;
}

[dir] #baixaPortalSelect .vs__dropdown-toggle{
  border-color: var(--primary) !important;
}
</style>

<script>

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from './shared/services/api'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  name: 'CndPortalDocumentosView',
  components: {
    vSelect,
    CndPortalDocumentosList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
  },
  data() {
    return {
      apiPortal: api,
      mostarArquivo: false,
      arquivo: '',
      empresaNome: '',
      botoesOption: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social',
        acaoClass: 'col-td-acao-2',
        disabledCheckbox: false,
        colunas: [
          { label: 'Razão social', field: 'razaoSocial' },
          { label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-tamanho-cnpj' },
          {
            label: 'RFB/PGFN',
            field: 'rfbPgfn',
            tdClass: 'text-center',
            type: 'custom',
            visible: true,
          },
          {
            label: 'FGTS',
            field: 'fgts',
            tdClass: 'text-center',
            type: 'custom',
            visible: true,
          },
          {
            label: 'Trabalhista',
            field: 'trabalhista',
            tdClass: 'text-center',
            type: 'custom',
            visible: true,
          },
          {
            label: 'MTE',
            field: 'mte',
            tdClass: 'text-center',
            type: 'custom',
            visible: true,
          },
          {
            label: 'Estadual',
            field: 'estadual',
            tdClass: 'text-center',
            type: 'custom',
            visible: true,
          },
          {
            label: 'Municipal',
            field: 'municipal',
            tdClass: 'text-center',
            type: 'custom',
            visible: true,
          },
        ],
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      filtrosDefaultDocs: [],
      filtrosRapidosDocs: [],
      filtrosRapidos: [
        {
          id: 'Regular',
          colunas: ['Situacao'],
          label: 'Regulares',
          valor: '1,2',
        },
        {
          id: 'Irregular',
          colunas: ['Situacao'],
          label: 'Irregulares',
          valor: '3',
        },
        {
          id: 'Motivo',
          colunas: ['Situacao'],
          label: 'Motivo não geração',
          valor: '4, 5, 6',
        },
        {
          id: 'EmManutencao',
          colunas: ['Situacao'],
          label: 'Em manutenção',
          valor: '5',
        },
        {
          id: 'EmpregadorNaoCadastrado',
          colunas: ['Situacao'],
          label: 'Empregador não cadastrado',
          valor: '6',
        },
      ],
      filtroString: [],
      filtroAtual: {},
      isLoading: false,
      arrayColunas: ['rfbPgfn', 'fgts', 'trabalhista', 'mte', 'estadual', 'municipal'],
      tipoDownload: null,
      opcoesBaixaEmLote: ['Arquivo Único', 'Por Empresa', 'Tipo de documento', 'Empresa e Tipo de documento'],
      empresasSelecionadas: [],
    }
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  created() {
    this.montarColunasPermitidas()
    this.visualizacao.evento = this.carregar
    this.tipoParcelamento = this.$route.meta.tipo
    this.filtrosRapidosDocs = this.filtrosDefaultDocs
  },
  methods: {
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.cndPortalDocumentosList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.cndPortalDocumentosList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    montarColunasPermitidas() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const acessos = userData.ability
      this.visualizacao.colunas.forEach(col => {
        const coluna = col.field.toLowerCase()
        if (!(coluna === 'razaosocial' || coluna === 'cadastrofederal')
        && !(coluna === 'fgts' && acessos.some(o => o.subject === 'cnd_fgts'))
        && !(coluna === 'rfbpgfn' && acessos.some(o => o.subject === 'cnd_federal'))
        && !(coluna === 'trabalhista' && acessos.some(o => o.subject === 'cnd_trabalhista'))
        && !(coluna === 'mte' && acessos.some(o => o.subject === 'cnd_mte'))
        && !(coluna === 'estadual' && acessos.some(o => o.subject === 'cnd_estadual'))
        && !(coluna === 'municipal' && acessos.some(o => o.subject === 'cnd_municipal'))) {
          this.visualizacao.colunas = this.visualizacao.colunas.filter(obj => obj.field.toLowerCase() !== coluna)
        }
      })
      this.montarFiltrosDeDocumentos()
    },
    montarFiltrosDeDocumentos() {
      this.visualizacao.colunas.forEach(col => {
        const coluna = col.field.toLowerCase()
        if (coluna === 'rfbpgfn') {
          this.filtrosDefaultDocs.push('CND RFB/PGFN')
        } else if (coluna === 'fgts') {
          this.filtrosDefaultDocs.push('CND FGTS')
        } else if (coluna === 'trabalhista') {
          this.filtrosDefaultDocs.push('CND Trabalhista')
        } else if (coluna === 'mte') {
          this.filtrosDefaultDocs.push('CND MTE')
        } else if (coluna === 'estadual') {
          this.filtrosDefaultDocs.push('CND Estadual')
        } else if (coluna === 'municipal') {
          this.filtrosDefaultDocs.push('CND Municipal')
        }
        if (this.filtrosDefaultDocs.length === 6) {
          this.filtrosDefaultDocs.unshift('Todos')
        }
      })
    },
    mudarDocSelecionado(docs) {
      if (docs && docs.find(find => find === 'Todos')) {
        this.filtrosRapidosDocs = ['Todos']
        this.visibleColunas()
      } else {
        this.filtrosRapidosDocs = this.filtrosDefaultDocs
        this.visibleColunas()
      }
      this.executarFiltro()
    },
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(pagina, tamanhoPagina, filtro, this.filtroString)
    },
    executarFiltro() {
      this.$refs.cndPortalDocumentosList.montarGrid(this.$refs.cndPortalDocumentosList.enviarServidor)
    },
    visibleColunas() {
      this.visualizacao.colunas.forEach(coluna => {
        if (this.filtroString.length > 0 && !this.filtroString.includes('Todos')) {
          if (!this.filtroString.some(s => s.replace('/', '').toLowerCase().includes(coluna.field.toLowerCase())) && this.arrayColunas.includes(coluna.field)) {
            // eslint-disable-next-line
            coluna.visible = false
          } else {
            // eslint-disable-next-line
            coluna.visible = true
          }
        } else {
          if (this.filtroString.length > 0) {
            this.filtroString = ['Todos']
          }
          // eslint-disable-next-line no-param-reassign
          coluna.visible = true
        }
      })
    },
    openModalVisualizacaoArquivo(row, coluna) {
      if (this.validarColuna(row, coluna)) {
        this.openVisualizacaoArquivo(row, coluna)
      } else {
        this.$erro('Não foi possível abrir a visualização de arquivo.')
        this.fecharArquivo()
      }
    },
    openVisualizacaoArquivo(row, coluna) {
      api.getURL(row.id, coluna)
        .then(payload => {
          this.arquivo = payload.data
          this.empresaNome = row.razaoSocial
          this.mostarArquivo = true
        })
        .catch(err => {
          this.$erro('Não foi possível abrir a visualização de arquivo.', err)
          this.fecharArquivo()
        })
    },
    fecharArquivo() {
      this.mostarArquivo = false
      this.arquivo = ''
    },
    enviarEmailLoteGeral() {
      let empresas = this.$refs.cndPortalDocumentosList.enviarServidor.linhasSelecionadas

      this.isLoading = true
      if (!empresas) {
        empresas = this.$refs.cndPortalDocumentosList.gridInfo.dados
      }
      const empresaEmail = empresas.map(empresa => ({ empresaId: empresa.id, emailsCC: null, email: empresa.email ? empresa.email : null }))
      this.doc = this.montarDoc()
      this.doc.empresasEmail = [...empresaEmail]
      api.enviarEmLote('Portal CNDs', this.doc).then(payload => {
        this.isLoading = false
        if (payload.data.length > 0) {
          this.empresasEmail = payload.data

          this.$refs.empresaSemEmailList.show()
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'E-mails enviados com sucesso',
            icon: 'fa-solid fa-circle-check',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.isLoading = false
        this.$erro('Erro ao enviar os e-mails', 'danger')
        throw err
      })
    },
    enviarEmailLinha(linha, email) {
      this.isLoading = true
      const empresaEmail = {
        empresaId: linha.id,
        email: email.emailsPara,
        emailAdicionalEmpresa: email.emailsCc,
        mensagensAdicional: email.mensagensAdicional,
        definirEmailPadrao: email.definirEmailPadroes,
      }

      api.enviarEmLote('CNDS Portal Documentos', { docs: this.filtroString, empresasEmail: [empresaEmail], filtro: this.filtroAtual })
        .then(() => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-mail enviado com sucesso',
              icon: 'fa-solid fa-circle-check',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.isLoading = false
          this.$erro('Problemas no envio do e-mail, confira os dados e tente novamente!', 'danger')
          throw err
        })
    },
    baixaEmLoteGeral() {
      this.isLoading = true
      const doc = this.montarDoc()
      api.baixaEmLote(doc, '')
      this.isLoading = false
      this.tipoDownload = null
    },
    montarDoc() {
      if (this.$refs.cndPortalDocumentosList) {
        const empresas = this.$refs.cndPortalDocumentosList.enviarServidor.linhasSelecionadas

        return {
          Agrupamento: this.tipoDownload,
          Docs: this.filtroString,
          Empresas: !empresas ? undefined : empresas.map(x => x.id),
          Filtro: this.filtroAtual,
        }
      }
      return undefined
    },
    validarColuna(row, coluna) {
      if ((coluna === 'rfbPgfn' && row.rfbPgfn)
       || (coluna === 'fgts' && row.fgts)
       || (coluna === 'trabalhista' && row.trabalhista)
       || (coluna === 'mte' && row.mte)
       || (coluna === 'estadual' && row.estadual)
       || (coluna === 'municipal' && row.municipal)) {
        return true
      }
      return false
    },
    getColorTag(situacao) {
      if (situacao === 'motivo') {
        return 'light-primary'
      }
      if (situacao === 'regular') {
        return 'light-success'
      }
      if (situacao === 'empregadornaocadastrado' || situacao === 'emmanutencao') {
        return 'light-warning'
      }
      if (situacao === 'irregular') {
        return 'light-danger'
      }
      return 'light-dark'
    },
    getDescricao(situacao) {
      if (situacao === 'motivo') {
        return 'Motivo'
      }
      if (situacao === 'regular') {
        return 'Regular'
      }
      if (situacao === 'empregadornaocadastrado') {
        return 'Empregador<br/>não cadastrado'
      }
      if (situacao === 'emmanutencao') {
        return 'Em manutenção'
      }
      if (situacao === 'irregular') {
        return 'Irregular'
      }
      return '-'
    },
  },
}
</script>
